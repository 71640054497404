import React from 'react';
import { graphql } from 'gatsby';

import { Metadata } from '@mangoart/gatsby-ui/components/Metadata';
import { PageTitle } from '@mangoart/gatsby-ui/components/ezagrar/PageTitle';
import DefaultLayout from '../layout/DefaultLayout';

const GebrauchtMaschinenPage = ({ data, location }) => {
  const { pageData, siteData } = data;
  const { siteMetadata } = siteData;
  const { metadata, opengraph, content } = pageData;
  return (
    <DefaultLayout>
      <Metadata
        data={metadata}
        opengraphData={opengraph}
        defaultTitle={siteMetadata.title}
        canonicalSiteUrl={siteMetadata.canonicalSiteUrl}
        path={location.pathname}
      />
      <PageTitle>{content.title}</PageTitle>
      <iframe
        src="https://www.landwirt.com/gebrauchte/iframe.php?type=group&amp;tid=129"
        width="1000px"
        height="1000px"
        frameBorder="0"
        id="landwirt_box"
      >
        <p>
          Ihr Browser kann leider keine eingebetteten Frames anzeigen:
          <br />
          Sie können die eingebettete Seite über den folgenden Verweis aufrufen:{' '}
          <a href="https://www.landwirt.com/gebrauchte/iframe.php?type=group&amp;tid=129">
            Gebrauchtmaschinen - Landwirt.com
          </a>
        </p>
      </iframe>
    </DefaultLayout>
  );
};

export default GebrauchtMaschinenPage;

export const GebrauchtMaschinenPageQuery = graphql`
  query GebrauchtmaschinenPageQuery {
    siteData: site {
      id
      siteMetadata {
        title
        canonicalSiteUrl
      }
    }
    pageData: cockpitPageGebrauchtmaschinen {
      content {
        title
      }
      metadata {
        title
        metakeywords
        metadescription
      }
      opengraph {
        title
        description
        type
        image {
          id
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }
`;
